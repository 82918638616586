<template>
    <popup-wallet-check v-if="globalStore.showPopup.walletCheck" />
    <popup-supplier-error v-if="globalStore.showPopup.errorRequest" />

    <div class="home-content">
        <div
            class="page-balance"
        >
            <div class="user-status">
                Ваш статус
                <span>{{ statusText }} {{ statusPercent }}</span>
            </div>

            <div class="user-balance">
                <!-- balance count -->
                <div class="user-balance-wrap">
                    <div class="user-balance-count-wrap">
                        <div class="user-balance-count">
                            <div class="user-balance-count-title">
                                Баланс
                            </div>

                            {{ balance }} &#8376;
                        </div>

                        <img
                            class="user-balance-icon"
                            src="@/assets/icon_user_balance.png"
                            alt="icon_user_balance"
                        >
                    </div>

                    <button
                        class="form-button-submit"
                        @click="handleSuccess"
                    >
                        Вывести деньги
                    </button>
                </div>
                <!-- balance calendar -->
                <div class="user-balance-calendar-wrap">
                    <div class="user-balance-calendar-data">
                        <div class="user-balance-calendar-year">
                            {{ currentYear }}
                        </div>
                        {{ currentMount }}
                    </div>
                    <div class="user-balance-calendar-control">
                        <img
                            class="user-balance-calendar-control-prev"
                            src="@/assets/icon_user_balance_mount_prev.png"
                            alt="icon_user_balance"
                            @click="handlePrevMountHistory"
                        >
                        <img
                            class="user-balance-calendar-control-next"
                            src="@/assets/icon_user_balance_mount_next.png"
                            alt="icon_user_balance"
                            @click="handleNextMountHistory"
                        >
                    </div>
                </div>
                <!-- balance calendar -->
                <div class="user-profit-wrap">
                    <div class="user-profit-count">
                        <div class="user-profit-count-title">
                            Доход за месяц
                        </div>
                        {{ profitMount }} &#8376;
                    </div>
                    <div class="user-profit-request">
                        <div class="user-profit-request-title">
                            Заявки за месяц
                        </div>
                        {{ requestsMount }}
                    </div>
                </div>
            </div>

            <!-- balance history -->
            <supplier-tables-profit :requests="supplierStore.wallet.history" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import { useGlobalStore } from '@/stores/global';
import { useSupplierStore } from '@/stores/supplier';

import SupplierTablesProfit from '@/components/supplier/SupplierWalletsTables.vue';
import PopupWalletCheck from '@/components/popup/PopupWalletCheck.vue';
import PopupSupplierError from '@/components/popup/PopupSupplierError.vue';

import { Wallet } from '@/types/Wallet';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/ru'
import { capitalizeFirstLetter } from '@/utils';
import { DATA_START_PROJECT } from '@/utils/constant';
dayjs.extend(localeData);
dayjs.locale('ru')

export default defineComponent({
  components: {
    SupplierTablesProfit,
    PopupWalletCheck,
    PopupSupplierError
  },
  setup () {
    const globalStore = useGlobalStore();
    const supplierStore = useSupplierStore();

    return {
      supplierStore,
      globalStore
    };
  },
  data() {
    const allMountsName = dayjs.months();
    const currentMountIndex = dayjs().month();
    const currentMount = capitalizeFirstLetter(allMountsName[ currentMountIndex ]);
    const currentYear = dayjs().year();
    return {
      currentMountIndex,
      allMountsName,
      currentMount,
      currentYear,
      dateFrom: dayjs().startOf('month').format(),
      dateTo: dayjs().endOf('month').format()
    }
  },
  computed: {
    statusPercent (): string {
      return `(${Math.min(this.supplierStore.wallet.history.length, 50) * 2}%)`;
    },
    statusText (): string {
      const walletCount = this.supplierStore.wallet.history.length;

      if (walletCount === 0) {
        return '';
      } else if (walletCount >= 1 && walletCount < 10) {
        return 'Black';
      } else if(walletCount >= 10 && walletCount < 25) {
        return 'Silver';
      } else if(walletCount >= 25 && walletCount < 50) {
        return 'Gold';
      } else {
        return 'Platinum';
      }
    },
    balance (): number {
      return this.supplierStore.wallet.balance;
    },
    profitMount (): number {
        return this.supplierStore.wallet.history.reduce((sum: number, wallet: Wallet) => sum + wallet.amount, 0);
    },
    requestsMount (): number {
      return this.supplierStore.requests.processed.length;
    }
  },
  created() {
      this.getWalletHistory();
      this.supplierStore.walletBalance();
  },
  methods: {
    getWalletHistory() {
      const { dateFrom, dateTo } = this;

      this.supplierStore.walletHistory({ dateFrom, dateTo });
    },
    setWalletHistory() {
      const { dateFrom, dateTo } = this;

      this.supplierStore.walletHistory({ dateFrom, dateTo });
    },
    handlePrevMountHistory() {
      const startDateProject = dayjs(DATA_START_PROJECT).startOf('month').valueOf(); // время первого дня начала проекта
      const prevDate = dayjs().month(this.currentMountIndex - 1).startOf('month').valueOf() // время первого дня предыдущего месяца

      if(startDateProject <= prevDate) {
        this.currentMountIndex -= 1;
        this.dateFrom = dayjs().month(this.currentMountIndex).startOf('month').format();
        this.dateTo = dayjs().month(this.currentMountIndex).endOf('month').format();
        this.currentMount = capitalizeFirstLetter(this.allMountsName[ dayjs().month(this.currentMountIndex).month() ]);
        this.currentYear = dayjs().month(this.currentMountIndex).year();

        this.getWalletHistory();
      }
    },
    handleNextMountHistory() {
      const currentMountIndex = dayjs().month();  // индекс текущего месяца

      if(this.currentMountIndex < currentMountIndex) {
        this.currentMountIndex += 1;
        this.dateFrom = dayjs().month(this.currentMountIndex).startOf('month').format();
        this.dateTo = dayjs().month(this.currentMountIndex).endOf('month').format();
        this.currentMount = capitalizeFirstLetter(this.allMountsName[ dayjs().month(this.currentMountIndex).month() ]);
        this.currentYear = dayjs().month(this.currentMountIndex).year();

        this.getWalletHistory();
      }
    },
    async handleSuccess() {

    }
  }
});
</script>

<style lang="less">
.page-balance {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  padding-top: 100px;
}

.user-status {
  margin: 0 12px;
  display: flex;
  justify-content: start;
  font-size: 20px;
  line-height: 24px;

  span {
    margin-left: 10px;
    font-weight: 700;
    font-size: 24px;
    color: #E88102;
    margin-bottom: 60px;
  }
}

.user-balance {
  display: flex;
  flex-direction: row;
}

.user-balance-wrap {
  background: #38363B;
  border-radius: 16px;
  padding: 24px;
  margin: 12px;

  .form-button-submit {
    width: 100%;
  }
}

.user-balance-count-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.user-balance-count {
  font-size: 50px;
  line-height: 60px;
  padding-right: 130px;
}

.user-balance-count-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 60px;
}

.user-balance-icon {
  display: block;
  margin-left: 24px;
}

.user-balance-calendar-wrap, .user-profit-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 260px;
  margin: 12px;
}

.user-profit-count, .user-profit-request, .user-balance-calendar-data {
  background: #38363B;
  border-radius: 16px;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: #E88102;
  padding: 24px;
  letter-spacing: -1px;
}

.user-profit-count-title, .user-profit-request-title, .user-balance-calendar-year {
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 7px;
  letter-spacing: 0;
}

.user-balance-calendar-data {
  text-align: center;
}

.user-balance-calendar-control {
  margin-top: 24px;
  background: #38363B;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.user-balance-calendar-control {
  padding: 39px 0;
}

.user-balance-calendar-control-prev, .user-balance-calendar-control-next {
  cursor: pointer;
  margin: 8px 12px;
}

.user-profit-request {
  margin-top: 24px;
}

</style>

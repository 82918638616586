<template>
    <div
        class="form-wrap"
    >
        <div
            class="form-content"
        >
            <div class="form-text-status-title">
                Спасибо
            </div>
            <div class="form-text-status-description">
                Вопрос отправлен в службу поддержки, ожидайте ответ на почту в течение нескольких часов.
            </div>
            <button class="form-button-submit">
                <router-link
                    to="/steam/#form_pay"
                    @click="setShowPopup"
                >
                    Спасибо
                </router-link>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useClientStore } from '@/stores/client';
import { useGlobalStore } from '@/stores/global';
import dayjs from 'dayjs';

export default defineComponent({
  setup () {
    const globalStore = useGlobalStore();
    return {
      setShowPopup: globalStore.setShowPopup
    };
  }
});
</script>

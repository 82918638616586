<template>
    <div class="home-content">
        <form-support />
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import FormSupport from '@/components/form/FormSupport';

export default defineComponent({
  components: {
    FormSupport
  }
});
</script>

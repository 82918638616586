export enum COMPONENT_NAME {
    FORM_PAY = 'formPay',
    FORM_PAY_FINISH = 'formPayFinish',
    FORM_LOGIN = 'formLogin',
    FORM_REGISTRATION = 'formRegistration',
    FORM_RESTORE_EMAIL = 'formRestoreEmail',
    FORM_RESTORE_PASSWORD = 'formRestorePassword',
    FORM_SUPPORT = 'formSupport',
    REQUEST_PAY = 'requestPay',
    REQUEST_PAY_SUCCESS = 'requestPaySuccess',
    requestCheck = 'requestCheck',
    walletCheck = 'walletCheck',
    PROFILE_VERIFICATION = 'profileVerification',
    successPay = 'successPay',
    checkPay = 'checkPay',
    SUCCESS_SUPPORT = 'successSupport',
    SUCCESS_RESTORE_EMAIL = 'successRestoreEmail',
    successRegistration = 'successRegistration',
    ERROR_REQUEST = 'errorRequest',
    ERROR_RESTORE_PASSWORD = 'errorRestorePassword',
    errorPay = 'errorPay',
    errorSessionPay = 'errorSessionPay',
    loadingPay = 'loadingPay',
}

export type COMPONENT_NAME_KEY = keyof typeof COMPONENT_NAME;
export type COMPONENT_NAME_METHOD = `${COMPONENT_NAME}`;
export type COMPONENT_NAME_STATE = { [key in COMPONENT_NAME]: boolean }

<template>
    <div
        class="form-fly-wrap"
    >
        <div
            class="form-content"
        >
            <h2>
                Заявка
                <span
                    class="form-close"
                    @click="closePopup"
                />
            </h2>
            <template v-if="!paymentUrl">
                <div class="form-pay-info-row">
                    <span>ID</span>
                    <span>{{ supplierStore.activeRequest.topupId }}</span>
                </div>
                <div class="form-pay-info-row">
                    <span>Сумма пополнения</span>
                    <span>{{ supplierStore.activeRequest.amountUsd.toFixed(2) }} &#8376;</span>
                </div>
                <div class="form-pay-info-row">
                    <span>Доход</span>
                    <span class="price">{{ (supplierStore.activeRequest.amountUsd * 0.05).toFixed(2) }} &#8376;</span>
                </div>
                <button
                    class="form-button-submit"
                    @click="requestPay"
                >
                    Оплатить
                </button>
                <div class="form-text-why-no-registration">
                    {{ timerData }}
                </div>
            </template>
            <iframe
                v-else
                class="payment-iframe"
                :src="paymentUrl"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { useGlobalStore } from '@/stores/global';
import { defineComponent } from 'vue';
import { useSupplierStore } from '@/stores/supplier';
import dayjs from 'dayjs';
import { COMPONENT_NAME } from '@/stores/types';

export default defineComponent({
  setup () {
    const supplierStore = useSupplierStore();
    const globalStore = useGlobalStore();

    return {
      supplierStore,
      globalStore
    };
  },
  data() {
    return {
      shiftTime: Date.now() + (60 * 2 * 1000), // 2 min
      currentTime: Date.now(),
      interval: 0,
      timer: 2,
      paymentUrl: ''
      // paymentUrl: 'http://192.168.31.164/supplier/request/pay/423423423444'
    }
  },
  computed: {
    timerData(): string {
        const diffTime = this.shiftTime - this.currentTime;
        const duration = dayjs.duration(diffTime, 'milliseconds');

        return `Оплатите в течении ${duration.minutes()} минут ${duration.seconds()} секунд`;
    }
  },
  mounted() {
    window.addEventListener('message', this.receiveIframeMessage)
    this.interval = setInterval(this.updateCurrentTime, 1000);
  },
  beforeUnmount() {
    window.removeEventListener('message', this.receiveIframeMessage)
    clearInterval(this.interval);
  },
  methods: {
    updateCurrentTime() {
      this.currentTime = Date.now();

      if(this.currentTime >= this.shiftTime) {
        this.closePopup()
      }
    },
    closePopup() {
      clearInterval(this.interval);
      this.supplierStore.requestsAvailableCancel(this.supplierStore.activeRequest.topupId)
      this.globalStore.hideAll()
    },
    async requestPay() {
      const { paymentUrl } = await this.supplierStore.requestsReservedProcess(this.supplierStore.activeRequest.topupId);
      this.paymentUrl = paymentUrl;
    },
    receiveIframeMessage (event: any) {
      if(event.data && event.data.type === 'supplierRequestId') {
        this.paymentUrl = '';
        this.globalStore.setShowPopup(COMPONENT_NAME.REQUEST_PAY_SUCCESS)
      }
    }
  }
});
</script>

<style lang="less">


</style>

import { defineStore } from 'pinia';
import { getWalletBalance, postDataLogin, postDataRegistration, postDataRestoreEmail, postWalletHistory } from '@/api';
import { getRequestsAvailable, getRequestsAvailableReserve, getRequestsProcessed, getRequestsReservedCancel, getRequestsReservedProcess } from '@/api/request';
import { useGlobalStore } from '@/stores/global';
import { RequestLogin, RequestRegistration, RequestWalletHistory } from '@/api/types';
import { TopUp } from '@/types/TopUp';
import { Wallet } from '@/types/Wallet';
import { getProfileVerification } from '@/api/profile';
import { postDataRestorePassword } from '@/api/supplier';
import { RequestRestorePassword } from '@/api/supplier/types';
import { jwtDecode } from 'jwt-decode';

export const useSupplierStore = defineStore({
    id: 'supplierStore',
    state: (): any =>
        ({
            authToken: '',
            verified: false,
            telegramReturnRedirectUrl: '',
            telegramVerificationRequired: false,
            activeRequest: {
                topupId: '',
                status: null,
                amountUsd: 0,
                account: '',
                paymentUrl: '',
                errorDescription: '',
                creationDate: 0,
                processedDate: 0
            },
            activeWallet: {
                date: '',
                type: '',
                amount: 0,
                status: ''
            },
            requests:{
                available: [],
                processed: []
            },
            wallet: {
                balance: 0,
                history: []
            }
        }),
    getters: {
        isAuth(state) {
            if(state.authToken) {
                const { exp } = jwtDecode(state.authToken) as {exp: number};

                if(exp * 1000 < Date.now()) {
                    this.logout()
                    return false;
                }
                return true;
            }

            return false;
        }
    },
    actions: {
        async setActiveRequest(request: TopUp) {
            this.activeRequest = request;
        },
        async setActiveWallet(wallet: Wallet) {
            this.activeWallet = wallet;
        },
        async login(param: RequestLogin) {
            try {
                const { token, success, errorMessage } = await postDataLogin(param);
                if(success) {
                    this.authToken = token;
                }
                return { success, errorMessage };
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();
            }
        },
        async logout() {
            this.authToken = '';
        },
        async registration(param: RequestRegistration) {
            try {
                const { token, success, errorMessage } = await postDataRegistration(param);
                if(success) {
                    this.authToken = token;
                }
                return { success, errorMessage };
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();
            }
        },
        async restore(param: RequestRegistration) {
            try {
                const { token, success, errorMessage } = await postDataRestoreEmail(param);
                if(success) {
                    this.authToken = token;
                }
                return { success, errorMessage };
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();
            }
        },
        async restorePassword(param: RequestRestorePassword) {
            try {
                return await postDataRestorePassword(param);
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();
            }
        },
        async requestsAvailable() {
            try {
                const { list } = await getRequestsAvailable({ token: this.authToken });
                if(list) {
                    const sort = list.sort((a, b) => a.creationDate - b.creationDate);
                    this.requests.available = sort.slice(0, 11);
                }
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();
            }
        },
        async requestsProcessed() {
            try {
                const { list } = await getRequestsProcessed({
                    token: this.authToken,
                    dateFrom: '2024-08-16T16:33:35.943Z',
                    dateTo: '2024-10-16T16:33:35.943Z'
                });
                if(list) {
                    const sort = list.sort((a, b) => a.processedDate - b.processedDate);
                    this.requests.processed = sort.slice(0, 11);
                }
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();
            }
        },
        async walletHistory({ dateFrom, dateTo }: RequestWalletHistory) {
            try {
                const { entries } = await postWalletHistory({
                    token: this.authToken,
                    dateFrom,
                    dateTo
                });
                if(entries) {
                    this.wallet.history = entries;
                }
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();
            }
        },
        async walletBalance() {
            try {
                const { balanceAmount } = await getWalletBalance({
                    token: this.authToken
                });
                if(balanceAmount) {
                    this.wallet.balance = balanceAmount;
                }
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();
            }
        },
        async profileVerification() {
            try {
                const { verified, token, telegramReturnRedirectUrl, telegramVerificationRequired } = await getProfileVerification({
                    token: this.authToken
                });

                if(verified) {
                    this.authToken = token;
                }

                this.telegramReturnRedirectUrl = telegramReturnRedirectUrl;
                this.telegramVerificationRequired = telegramVerificationRequired;
                this.verified = verified;
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();
            }
        },
        async requestsAvailableReserve(topupId: string) {
            try {
                const { success, errorMessage, kztAmount } = await getRequestsAvailableReserve({
                    token: this.authToken,
                    topupId
                });

                if(!success) {
                    const globalStore = useGlobalStore();
                    globalStore.setErrorMessage(errorMessage);
                    globalStore.showErrorRequestPopup();
                }
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();
            }
        },
        async requestsAvailableCancel(topupId: string) {
            try {
                const { success, errorMessage } = await getRequestsReservedCancel({
                    token: this.authToken,
                    topupId
                });

                if(!success) {
                    const globalStore = useGlobalStore();
                    globalStore.setErrorMessage(errorMessage);
                    globalStore.showErrorRequestPopup();
                }
            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();
            }
        },
        async requestsReservedProcess(topupId: string) {
            try {
                return await getRequestsReservedProcess({
                    token: this.authToken,
                    topupId
                });

            } catch (e) {
                const globalStore = useGlobalStore();
                globalStore.showErrorRequestPopup();
            }
        }
    }
});

import {
    RequestAvailableCancel,
    RequestAvailableReserve,
    RequestRequests, RequestReservedProcess,
    ResponseAvailableCancel,
    ResponseAvailableReserve,
    ResponseRequests, ResponseReservedProcess
} from '@/api/request/types';

export const REQUEST_URL_API = 'https://topupme.kz/api/v1/supplier/requests';

export const getRequestsAvailable = async (param: RequestRequests): Promise<ResponseRequests> => {
    const response = await fetch(`${REQUEST_URL_API}/available/list`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${param.token}`
        }
    });
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`)
    }
}

export const getRequestsProcessed = async (param: RequestRequests): Promise<ResponseRequests> => {
    const response = await fetch(`${REQUEST_URL_API}/processed/list`, {
        body: JSON.stringify(param),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${param.token}`
        }
    });
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`)
    }
}

export const getRequestsAvailableReserve = async (param: RequestAvailableReserve): Promise<ResponseAvailableReserve> => {
    const response = await fetch(`${REQUEST_URL_API}/available/reserve`, {
        body: JSON.stringify(param),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${param.token}`
        }
    });
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`)
    }
}

export const getRequestsReservedCancel = async (param: RequestAvailableCancel): Promise<ResponseAvailableCancel> => {
    const response = await fetch(`${REQUEST_URL_API}/reserved/cancel`, {
        body: JSON.stringify(param),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${param.token}`
        }
    });
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`)
    }
}

export const getRequestsReservedProcess = async (param: RequestReservedProcess): Promise<ResponseReservedProcess> => {
    const response = await fetch(`${REQUEST_URL_API}/reserved/process`, {
        body: JSON.stringify(param),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${param.token}`
        }
    });
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`)
    }
}

<template>
    <div
        id="fag"
        class="f-a-g-wrap"
    >
        <div
            class="f-a-g-content"
        >
            <div class="f-a-g-title">
                FAQ
            </div>

            <el-collapse accordion>
                <el-collapse-item
                    title="Что следует проверить?"
                    name="1"
                >
                    <ul>
                        <li>Указан логин аккаунта Steam, не никнейм.</li>
                        <li>
                            Страна аккаунта Steam может быть любой. Валюта пополнения USD будет конвертирована в валюту аккаунта.
                        </li>
                        <li>
                            Есть ли у вас на аккаунте красная табличка (КТ)? Советуем проверить до пополнения, средства вернуть при успешном пополнении невозможно.
                        </li>
                        <li>
                            Есть ли у вас на аккаунте красная табличка (КТ)? Советуем проверить до пополнения, средства вернуть при успешном пополнении невозможно.
                        </li>
                    </ul>
                </el-collapse-item>
                <el-collapse-item
                    title="Что такое логин Steam и где его посмотреть?"
                    name="2"
                >
                    Логин аккаунта Steam - используется для авторизации в аккаунт Steam.
                    Еще его можно посмотреть, нажав на свой ник на платформе Steam и перейти на страницу «Об аккаунте».
                </el-collapse-item>
                <el-collapse-item
                    title="Какие карты сервис принимает для пополнения Steam?"
                    name="3"
                >
                    Действующие банковские карты Visa, Mastercard, Мир любой страны.
                </el-collapse-item>
                <el-collapse-item
                    title="Не приходят деньги на кошелек стим. Что делать?"
                    name="4"
                >
                    Пополнение Steam через все эти платежные методы происходит в течение 15 минут.
                    Если средства на кошелек стим не поступили в течение этого времени, пожалуйста, обратитесь в службу нашей поддержки с вашим email, логином и суммой
                    (так мы быстрее проверим статус платежа и дадим ответ).
                </el-collapse-item>
                <el-collapse-item
                    title="Не совпадает сумма пополнения баланса стим. Что делать?"
                    name="5"
                >
                    При пополнении Steam происходит конвертация средства в разных валютах и иногда сумма может отличаться на 1-5% от указанной при самой оплате.
                    Невозможно предусмотреть незначительные изменения во всех случаях.
                </el-collapse-item>
                <el-collapse-item
                    title="Как долго приходят деньги на кошелек Steam. Как проверить?"
                    name="6"
                >
                    Если вы соблюдали все требования для пополнения (указали корректный логин аккаунта), то пополнение Steam поступает на счет в течение 15 минут.
                    Если этого не произошло, пожалуйста, обратитесь в службу поддержки с указанием вашего email.
                </el-collapse-item>
                <el-collapse-item
                    title="Указали неверный логин аккаунта Steam"
                    name="7"
                >
                    Если вы указали некорректный логин аккаунта или указали никнейм вместо логина и он существует в Steam,
                    то деньги уйдут другому пользователю и вернуть их невозможно. Пожалуйста, будьте внимательны при создании заявки.
                </el-collapse-item>
                <el-collapse-item
                    title="Как пополнить аккаунт Steam и сохранить регион?"
                    name="8"
                >
                    При создании нового аккаунта Steam укажите вашу страну и добавьте 2 бесплатных игры в библиотеку Steam. После этого пополняйте баланс своего аккаунта.
                    Для некоторых ГЕО возможны ограничения, точную информацию следует смотреть на форумах или на официальном сайте Steam.
                </el-collapse-item>
            </el-collapse>
        </div>
    </div>
</template>

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_popup_error = _resolveComponent("popup-error")!
  const _component_popup_restore_password_error = _resolveComponent("popup-restore-password-error")!
  const _component_popup_success_restore_email = _resolveComponent("popup-success-restore-email")!
  const _component_popup_success_registration = _resolveComponent("popup-success-registration")!
  const _component_form_login = _resolveComponent("form-login")!
  const _component_form_registration = _resolveComponent("form-registration")!
  const _component_form_restore_email = _resolveComponent("form-restore-email")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.globalStore.showPopup.errorRequest)
      ? (_openBlock(), _createBlock(_component_popup_error, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.globalStore.showPopup.errorRestorePassword)
      ? (_openBlock(), _createBlock(_component_popup_restore_password_error, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.globalStore.showPopup.successRestoreEmail)
      ? (_openBlock(), _createBlock(_component_popup_success_restore_email, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.globalStore.showPopup.successRegistration)
      ? (_openBlock(), _createBlock(_component_popup_success_registration, { key: 3 }))
      : (_ctx.globalStore.showPopup.formLogin)
        ? (_openBlock(), _createBlock(_component_form_login, { key: 4 }))
        : (_ctx.globalStore.showPopup.formRegistration)
          ? (_openBlock(), _createBlock(_component_form_registration, { key: 5 }))
          : (_ctx.globalStore.showPopup.formRestoreEmail)
            ? (_openBlock(), _createBlock(_component_form_restore_email, { key: 6 }))
            : _createCommentVNode("", true)
  ]))
}
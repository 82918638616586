<template>
    <div
        class="template-navigation"
    >
        <div
            class="template-navigation-icon-mobile"
            :class="{
                'close' : mobileMenuOpen
            }"
            @click="handleMobileMenuChange"
        />
        <div
            class="template-navigation-link template-navigation-active-link"
            :class="{
                'template-navigation-link-full' : mobileMenuOpen
            }"
        >
            <router-link
                to="/supplier"
            >
                Заявки
            </router-link>
            <router-link
                to="/supplier/balance"
            >
                Баланс
            </router-link>
            <router-link
                to="/supplier/fag"
            >
                FAQ
            </router-link>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useGlobalStore } from '@/stores/global';
export default defineComponent({
  setup () {
    const globalStore = useGlobalStore();
    return {
      globalStore
    };
  },
  data() {
    return {
      mobileMenuOpen: false
    }
  },
  methods: {
    handleMobileMenuChange() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
      document.body.style.overflow = this.mobileMenuOpen ? 'hidden' : 'visible';
    }
  }
});
</script>

<style lang="less">

</style>

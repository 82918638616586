<template>
    <div

        class="template-table"
    >
        <div

            class="template-table-wrap"
        >
            <table>
                <thead>
                    <tr>
                        <th>Сумма</th>
                        <th>Доход</th>
                        <th class="time">
                            Оставшееся время
                        </th>
                        <th>&#x20;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="request in requests"
                        :key="request.topupId"
                    >
                        <template v-if="currentTime < (request.creationDate + shift) * 1000">
                            <td>{{ request.amountUsd.toFixed(2) }} {{ currencySymbol }}</td>
                            <td class="price">
                                {{ (request.amountUsd * 0.05).toFixed(2) }} {{ currencySymbol }}
                            </td>
                            <td>{{ timerData(request.creationDate) }}</td>
                            <td>
                                <button @click="handlePayRequest(request)">
                                    Оплатить
                                </button>
                            </td>
                        </template>
                    </tr>
                </tbody>
                <!--                <tfoot>-->
                <!--                    <tr>-->
                <!--                        <td-->
                <!--                            colspan="4"-->
                <!--                            class="template-table-paginator"-->
                <!--                        >-->
                <!--                            <div class="template-paginator">-->
                <!--                                <button class="paginator-prev" />-->
                <!--                                <button class="paginator-dots" />-->
                <!--                                <button class="paginator-page">-->
                <!--                                    1-->
                <!--                                </button>-->
                <!--                                <button class="paginator-page active">-->
                <!--                                    2-->
                <!--                                </button>-->
                <!--                                <button class="paginator-page">-->
                <!--                                    3-->
                <!--                                </button>-->
                <!--                                <button class="paginator-dots" />-->
                <!--                                <button class="paginator-next" />-->
                <!--                            </div>-->
                <!--                        </td>-->
                <!--                    </tr>-->
                <!--                </tfoot>-->
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue';
import { useGlobalStore } from '@/stores/global';
import { TopUp } from '@/types/TopUp';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useSupplierStore } from '@/stores/supplier';
import { COMPONENT_NAME } from '@/stores/types';
import { currencySymbol } from '@/utils';
import { decodeHtmlEntity } from '@/utils/html_entity';
dayjs.extend(duration);

export default defineComponent({
  props: {
    requests: {
      type: Array as PropType<TopUp[]>,
      required: true
    }
  },
  setup () {
    const globalStore = useGlobalStore();
    const supplierStore = useSupplierStore();

    supplierStore.requestsAvailable()

    return {
      globalStore,
      supplierStore
    };
  },
  data() {
    return {
      shift: (3600 * 24 * 15),
      // shift: 60 * 15, // 15 минут
      currentTime: Date.now(),
      interval: 0
    }
  },
  computed: {
    currencySymbol() {
      return decodeHtmlEntity(currencySymbol[ 'USD' ]);
    },
    timerData() {
      return (time: number) => {
        time += this.shift;
        const diffTime = (time * 1000) - this.currentTime;
        const duration = dayjs.duration(diffTime, 'milliseconds');
        const twoDP = (n: number, t: boolean = true) => (n <= 9 ? `0${n}${t ? ':' : ''}` : `${n}${t ? ':' : ''}`);

        // return `${twoDP(duration.days())}${twoDP(duration.hours())}${twoDP(duration.minutes())}${twoDP(duration.seconds(), false)}`;
        return `${twoDP(duration.minutes())}${twoDP(duration.seconds(), false)}`;
      };
    }
  },
  mounted() {
    this.interval = setInterval(this.updateCurrenTime, 1000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    updateCurrenTime() {
      this.currentTime = Date.now();
      this.supplierStore.requestsAvailable()
    },
    async handlePayRequest(request: TopUp) {
      await this.supplierStore.profileVerification()

      if(this.supplierStore.verified) {
        this.supplierStore.setActiveRequest(request)
        this.supplierStore.requestsAvailableReserve(request.topupId)
        this.globalStore.setShowPopup(COMPONENT_NAME.REQUEST_PAY)
      } else {
        this.globalStore.setShowPopup(COMPONENT_NAME.PROFILE_VERIFICATION)
      }
    }
  }
});
</script>

<style lang="less">
@import "~@/styles/table.less";
</style>

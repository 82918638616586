import {
    RequestTopUpCheck,
    RequestTopUpCreate,
    ResponseStartSession,
    ResponseTopUpForId,
    ResponseTopUpCheck,
    ResponseTopUpList,
    ResponseTopUpCreate,
    ResponseTopUpCount,
    RequestSupportMessage,
    ResponseSupportMessage,
    RequestLogin,
    ResponseLogin,
    RequestRegistration,
    ResponseRegistration,
    RequestWalletHistory,
    ResponseWalletHistory,
    RequestWalletBalance,
    ResponseWalletBalance
} from '@/api/types';

const CLIENT_URL_API = 'https://topupme.kz/api/v1/client';
const SUPPLIER_URL_API = 'https://topupme.kz/api/v1/supplier';

export const getDataStartSession = async (): Promise<ResponseStartSession> => {
    const response = await fetch(`${CLIENT_URL_API}/topup/start-session`);
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`)
    }
}

export const getDataTopUpForId = async (topupId: string): Promise<ResponseTopUpForId | number> => {
    const response = await fetch(`${CLIENT_URL_API}/topup/get?${new URLSearchParams({ topupId })}`, { method: 'GET' });
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`)
    }
}

export const getDataTopUpList = async (): Promise<ResponseTopUpList> => {
    const response = await fetch(`${CLIENT_URL_API}/topup/list` );
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`)
    }
}

export const getDataTopUpCount = async (): Promise<ResponseTopUpCount> => {
    const response = await fetch(`${CLIENT_URL_API}/topup/count` );
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`)
    }
}

export const postDataTopUpCheck = async (param: RequestTopUpCheck): Promise<ResponseTopUpCheck> => {
    const response = await fetch(`${CLIENT_URL_API}/topup/check`,{
        body: JSON.stringify(param),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    });

    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`)
    }
}

export const postDataTopUpCreate = async (param: RequestTopUpCreate): Promise<ResponseTopUpCreate> => {
    const response = await fetch(`${CLIENT_URL_API}/topup/create`, {
        body: JSON.stringify(param),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    });
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`)
    }
}

export const postDataSupportMessage = async (param: RequestSupportMessage): Promise<ResponseSupportMessage> => {
    const response = await fetch(`${CLIENT_URL_API}/support/message`, {
        body: JSON.stringify(param),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    });
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`)
    }
}

export const postDataLogin = async (param: RequestLogin): Promise<ResponseLogin> => {
    const response = await fetch(`${SUPPLIER_URL_API}/auth/sign-in`, {
        body: JSON.stringify(param),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    });
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`)
    }
}

export const postDataRegistration = async (param: RequestRegistration): Promise<ResponseRegistration> => {
    const response = await fetch(`${SUPPLIER_URL_API}/auth/sign-up`, {
        body: JSON.stringify(param),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    });
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`)
    }
}

export const postDataRestoreEmail = async (param: RequestRegistration): Promise<ResponseRegistration> => {
    const response = await fetch(`${SUPPLIER_URL_API}/auth/recover-request`, {
        body: JSON.stringify(param),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    });
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`)
    }
}

export const postWalletHistory = async (param: RequestWalletHistory): Promise<ResponseWalletHistory> => {
    const response = await fetch(`${SUPPLIER_URL_API}/wallet/history`, {
        body: JSON.stringify(param),
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${param.token}`
        }
    });
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`)
    }
}

export const getWalletBalance = async (param: RequestWalletBalance): Promise<ResponseWalletBalance> => {
    const response = await fetch(`${SUPPLIER_URL_API}/wallet/balance`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${param.token}`
        }
    });
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`)
    }
}

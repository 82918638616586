import { defineStore } from 'pinia';
import { COMPONENT_NAME, COMPONENT_NAME_METHOD, COMPONENT_NAME_STATE } from '@/stores/types';
import { isEnum } from '@/utils';

export const useGlobalStore = defineStore({
    id: 'globalStore',
    state: (): any =>
        ({
            showPopup: {
                formPay: true,
                formPayFinish: false,
                formLogin: false,
                formRegistration: false,
                formRestoreEmail: false,
                formRestorePassword: false,
                formSupport: false,
                requestPay: false,
                requestPaySuccess: false,
                requestCheck: false,
                walletCheck: false,
                profileVerification: false,
                successPay: false,
                checkPay: false,
                successSupport: false,
                successRestoreEmail: false,
                successRegistration: false,
                errorRequest: false,
                errorPay: false,
                errorSessionPay: false,
                errorRestorePassword: false,
                loadingPay: false
            } as COMPONENT_NAME_STATE,
            errorMessage: ''
        }),
    actions: {
        hideAll() {
            for (const key in this.showPopup) {
                this.showPopup[ key ] = false
            }
        },
        setErrorMessage(errorMessage: string ) {
            this.errorMessage = errorMessage;
        },
        setShowPopup(name: COMPONENT_NAME_METHOD ) {
            if(isEnum(name, COMPONENT_NAME)) {
                this.hideAll();
                this.showPopup[ name ] = true;
            }
        },
        showErrorRequestPopup() {
            this.setShowPopup(COMPONENT_NAME.ERROR_REQUEST);
        }
    }
});

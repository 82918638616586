import {
    RequestRestorePassword,
    ResponseRestorePassword
} from '@/api/supplier/types';

const SUPPLIER_URL_API = 'https://topupme.kz/api/v1/supplier';

export const postDataRestorePassword = async (param: RequestRestorePassword): Promise<ResponseRestorePassword> => {
    const response = await fetch(`${SUPPLIER_URL_API}/auth/recover-password`, {
        body: JSON.stringify(param),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    });
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`)
    }
}

import {
    RequestProfileVerification,
    ResponseProfileVerification
} from '@/api/profile/types';

export const PROFILE_URL_API = 'https://topupme.kz/api/v1/supplier/profile';

export const getProfileVerification = async (param: RequestProfileVerification): Promise<ResponseProfileVerification> => {
    const response = await fetch(`${PROFILE_URL_API}/verification`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${param.token}`
        }
    });
    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Status ${response.status}`)
    }
}

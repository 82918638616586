<template>
    <div

        class="template-table"
    >
        <div

            class="template-table-wrap"
        >
            <table>
                <thead>
                    <tr>
                        <th>Сумма</th>
                        <th>Доход</th>
                        <th class="time">
                            Дата
                        </th>
                        <th>&#x20;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="request in requests"
                        :key="request.topupId"
                    >
                        <td>{{ request.amountUsd.toFixed(2) }} {{ currencySymbol }}</td>
                        <td class="price">
                            {{ (request.amountUsd * 0.05).toFixed(2) }} {{ currencySymbol }}
                        </td>
                        <td>{{ convertTime(request.creationDate) }}</td>
                        <td>
                            <button @click="handlePayRequest(request)">
                                Чек операции
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue';
import { useGlobalStore } from '@/stores/global';
import { TopUp } from '@/types/TopUp';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useSupplierStore } from '@/stores/supplier';
import { decodeHtmlEntity } from '@/utils/html_entity';
import { currencySymbol } from '@/utils';

dayjs.extend(duration);

export default defineComponent({
  props: {
    requests: {
      type: Array as PropType<TopUp[]>,
      required: true
    }
  },
  setup () {
    const globalStore = useGlobalStore();
    const supplierStore = useSupplierStore();
    supplierStore.requestsProcessed()
    return {
      globalStore,
      supplierStore
    };
  },
  data() {
    return {

    }
  },
  computed: {
    convertTime() {
      return (time: number) => {
        return dayjs(time).format('DD.MM.YYYY HH:mm');
      };
    },
    currencySymbol() {
      return decodeHtmlEntity(currencySymbol[ 'KZT' ]);
    }
  },
  methods: {
    handlePayRequest(request: TopUp) {
      this.globalStore.setShowPopup('requestCheck')
      this.supplierStore.setActiveRequest(request)
    }
  }
});
</script>

<style lang="less">
@import "~@/styles/table.less";
</style>

<template>
    <div class="template-bg">
        <div class="template-wrap">
            <div class="template-header-wrap">
                <div class="template-header-logo">
                    <router-link
                        to="/steam/#form_pay"
                    >
                        <img
                            src="@/assets/logo_template.svg"
                            alt="topupme"
                        >
                    </router-link>
                </div>
                <steam-navigation />
            </div>
            <div class="template-content-wrap">
                <slot />
            </div>

            <div
                class="template-footer-wrap"
            >
                <div class="template-footer-content-top">
                    <div class="template-footer-logo">
                        <router-link
                            to="/steam/#form_pay"
                        >
                            <img
                                src="@/assets/logo_template.svg"
                                alt="topupme"
                            >
                        </router-link>
                    </div>
                    <steam-navigation />
                </div>


                <div class="template-footer-content-center">
                    <div class="template-footer-contact">
                        <h3>Контакты:</h3>

                        <p>
                            ТОО «Квиктум», 201140008964<br>
                            050012, Казахстан,<br>
                            город Алматы, Алмалинский район, ул. Мауленова, д. 96/77
                        </p>
                        <p>
                            <a href="mailto:info@quicktum.kz">info@quicktum.kz</a>
                        </p>
                    </div>
                    <div class="template-footer-logo-qt">
                        <a
                            target="_blank"
                            href="https://quicktum.kz/"
                        >
                            <img
                                src="@/assets/logo_qt.svg"
                                alt="quicktum"
                            >
                        </a>
                    </div>
                </div>

                <div class="template-footer-content-bottom">
                    <a
                        target="_blank"
                        href="../../download/Положение_конфиденциальности_и_обработки_персональных_данных_1.pdf"
                    >
                        Политика конфиденциальности
                    </a>
                    <a
                        target="_blank"
                        href="../../download/Публичная_оферта_для_плательщика_1_1.pdf"
                    >
                        Пользовательское соглашение
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useGlobalStore } from '@/stores/global'
import SteamNavigation from '@/components/SteamNavigation.vue';
export default defineComponent({
  components: {
    SteamNavigation
  }
});
</script>
